<template>
  <section class="hero">
      <v-row>
        <v-col align="center">
          <v-img src="@/assets/logo_transparent.png" />
          <p>Your trusted partner in innovative software solutions.</p>
      </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <button @click="scrollToContact">Get in Touch</button>
        </v-col>
      </v-row>

  </section>
</template>

<script>
export default {
  methods: {
    scrollToContact() {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.hero {
  height: 100vh;
  text-align: center;
  align-items: center;
  padding: 50px;
  background: #B5B3B0;
}
.hero button {
  margin-left: auto;
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.hero .v-img
{
    max-width: 400px;
    min-width: 30px;
    height: auto;
}
</style>
