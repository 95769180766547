<template>
  <footer>
    <p>&copy; 2024 Picklebear Systems.</p>
    <p>All rights reserved.</p>
  </footer>
</template>

<style scoped>
footer {
  text-align: center;
  padding: 10px;
  background: #333;
  color: #fff;
}
</style>
