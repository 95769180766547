<template>
  <section id="services">
    <v-carousel 
      cycle
      interval="3000"
      hide-delimiter-background 
      show-arrows-on-hover 
      height="100vh"
    >
      <v-carousel-item 
        v-for="(service, index) in services" 
        :key="index" 
        class="carousel-item"
        eager
      >
        <v-sheet class="carousel-sheet">
          <v-row justify="center" class="carousel-title">
            <v-card width ="100%">
              <h1>{{ service.title }}</h1>
            </v-card>
          </v-row>
          <v-row justify="center" class="carousel-content">
            <img :src="service.image" alt="Service Image" eager/>
          </v-row>
          <v-row>
            <v-card-text>{{ service.description }} Test</v-card-text>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
export default {
  data() {
    return {
      services: [
        { 
          title: 'Custom Software Development',
          description: 'We build custom software tailored to your business needs.',
          image: require('@/assets/round_table.webp')
        },
        {
          title: 'Data Analytics',
          description: 'Data-driven solutions to empower informed decision-making.',
          image: require('@/assets/etl.webp')
        },
        {
          title: "Cloud Infrastructure and Serverless Solutions",
          description: "Development and deployment of serverless applications using AWS Lambda, Google Cloud Platform, and Terraform.",
          image: require('@/assets/datacenter.webp')
        } 

       // {
        //  "title": "Project Management",
         // description: "Extensive experience in coordinating onshore and offshore teams, handling project documentation, design, proof-of-concept development, and task distribution."
       // }
      ]
    }
  }
}
</script>

<style scoped>
.services {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}

.service img {
  flex-grow: inherit;
  display:block;
  margin:auto;
}

.carousel-title {
  position: absolute;
  width: 100vw;
  text-align: center;
  z-index: 2;
  color: black;
  font-size: 2rem;
  font-weight: bold;
}

.v-card {
  color: white;
  background-color: #333;
}
</style>
