<template>
  <section id="contact" class="contact">
    <h3>Contact Us</h3>
    bogliconsulting@gmail.com <br />
    (941) 266-2365
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submitForm() {
      alert(`Message sent! Thank you, ${this.name}.`);
      this.name = this.email = this.message = '';
    }
  }
}
</script>

<style scoped>
.contact {
  height: 100vh;
  text-align: center;
}
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
}
button {
  padding: 10px;
  background: #333;
  color: #fff;
  border: none;
}
</style>
