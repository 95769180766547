
<template>
  <div id="app">
    <pbHeader />
    <pbHero />
    <pbServices />
    <pbContactForm />
    <br />
    <pbFooter />
  </div>
</template>

<script>
import pbHeader from './components/pbHeader.vue'
import pbHero from './components/pbHero.vue'
import pbServices from './components/pbServices.vue'
import pbContactForm from './components/ContactForm.vue'
import pbFooter from './components/pbFooter.vue'

export default {
  components: {
    pbHeader,
    pbHero,
    pbServices,
    pbContactForm,
    pbFooter
  }
}
</script>

<style>
/* General styles can go here */
body {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background: #B5B3B0;
}
h3 {
  padding-left: 5px;
}
</style>
