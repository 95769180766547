<template>
  <header>
    <v-img max-width="70px" src ="@/assets/logo.png" />
    <nav>
      <a href="#services">Services</a>
      <a href="#contact">Contact</a>
    </nav>
    <button class="login-btn">Login / Register </button>
  </header>
</template>

<style scoped>
header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  color: #fff;
  padding: 5px;
}
nav {
  width:50%;
  display: flex;
  align-items: left;
  justify-content: left;
  gap: 15px;
}
nav a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}
.login-btn {
  margin-left: auto;
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-btn:hover {
  background-color: #45a049;
}
</style>
